<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group class="mb-0">
                    <b-button variant="primary" @click="getReport">
                        {{ $t('get_report') }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import LecturerReportService from "@/services/LecturerReportService"

export default {
    methods: {
        getReport() {
            LecturerReportService.getLecturerReport()
                .then((response) => {
                    this._downloadFile(response, this.$t('lecturer_report') + '.xlsx')
                    this.processing = false
                })
        }
    }
}
</script>
